<template>
    <div id="login">
        <div class="left-section">
            <div class="content">
                <img class="logo" src="~assets/logo.png">
                <div class="login-swiper-wrap">
                    <swiper class="login-swiper" ref="mySwiper" :options="swiperOptions" :auto-update="true">
                        <swiper-slide v-for="item in sources" :key="item.rowid">
                            <div class="img-wrap">
                                <img v-if="item.type==='image'" ref="media" class="media  swiper-lazy" :data-src="item.src" ondragstart="return false;">
                                <video-component v-else ref="media" class="media" :options="options"></video-component>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
                <record-footer class="record-wrap"></record-footer>
            </div>
        </div>
        <div class="right-section">
            <div v-if="!selectable" class="card">
                <div class="urs-area">
                    <div v-show="current === 'pwdLogin' || current === 'mesgLogin' || current === 'wechatLogin'" class="urs-login">
                        <ul ref="urs-login-tab" class="urs-login-tab" @click="handleTarget">
                            <template v-if="current === 'pwdLogin' || current === 'mesgLogin'">
                                <li data-name="pwdLogin" class="target" id="pwd-login">密码登录</li>
                                <li data-name="mesgLogin" id="message-login">短信登录</li>
                            </template>
                            <li v-else data-name="wechatLogin" key="wechat" class="target">微信扫码</li>
                        </ul>
                        <div v-if="current === 'pwdLogin' || current === 'mesgLogin'" class="usrs-wechat" @click="goTo"></div>
                        <div v-if="current === 'wechatLogin'" class="usrs-pc" @click="goTo('wechat')"></div>
                        <!-- 账号密码登录 -->
                        <div v-show="current==='pwdLogin'" class="urs-pwd-login urs-login-common">
                            <Form ref="formPwdLogin" :model="formPwdLogin" :rules="ruleInline" inline>
                                <FormItem prop="user" class="formItem">
                                    <Input id="pwd_login_account" v-model.trim="formPwdLogin.user" size="large" placeholder="请输入手机号" @keyup.native.enter="handleSubmit('formPwdLogin')">
                                        <template #prefix>
                                            <areas-component :areas="areas" v-model="areaCode"></areas-component>
                                        </template>
                                    </Input>
                                </FormItem>
                                <FormItem prop="password" class="formItem">
                                    <Input id="pwd_login_passwd" v-model="formPwdLogin.password" size="large" type="password" placeholder="请输入6-30位密码" @keyup.native.enter="handleSubmit('formPwdLogin')" />
                                </FormItem>
                                <FormItem prop="code" class="formItem">
                                   <Input id="pwd_login_captcha" class="img-code-wrap" size="large" type="text" v-model="formPwdLogin.code" placeholder="请输入图形验证码" @keyup.native.enter="handleSubmit('formPwdLogin')" >
                                     <img class="img-code pointer" :src="imgCode" slot="append" @click="changeImgCode">
                                  </Input>
                                </FormItem>
                            </Form>
                            <div class="tips">
                                <span class="message-login" @click="handleUrsLogin(1)">短信验证码登录</span>
                                <span class="forget-pwd float-right" @click="changeCurrent('changePwd', '更改密码', 'formPwdLogin')">忘记密码</span>
                            </div>
                            <Button id="pwd_login_cmt" class="confirm-button" type="primary" size="large" :loading="loading" @click="handleSubmit('formPwdLogin')">登录</Button>

                        </div>
                        <!-- 短信验证码登录 -->
                        <div v-show="current==='mesgLogin'" class="urs-message-login urs-login-common">
                            <Form ref="formMesLogin" :model="formMesLogin" :rules="ruleInline" inline>
                                <FormItem prop="user" class="formItem">
                                    <Input id="sms_login_account" type="text" size="large" v-model="formMesLogin.user" placeholder="请输入手机号" @keyup.native.enter="handleSubmit('formMesLogin')">
                                        <template #prefix>
                                            <areas-component :areas="areas" v-model="areaCode"></areas-component>
                                        </template>
                                    </Input>

                                </FormItem>
                                <br>
                                <FormItem prop="message" style="width: 223px;">
                                    <Input id="sms_login_msg" type="text" size="large" v-model="formMesLogin.message" placeholder="请输入验证码" @keyup.native.enter="handleSubmit('formMesLogin')" />

                                </FormItem>
                                <span id="sms_login_verify" class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('formMesLogin', 'login')">获取验证码</span>
                                <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
                            </Form>
                            <div class="tips">
                                <span class="message-login" @click="handleUrsLogin(0)">账号密码登录</span>
                            </div>
                            <Button id="sms_login_cmt" class="confirm-button" size="large" type="primary" @click="handleSubmit('formMesLogin')">登录</Button>

                        </div>
                        <!-- 微信登录 -->
                        <div v-show="current==='wechatLogin'" class="urs-wechat-login">
                            <div class="img-wrap">
                                <div v-show="!agreed" class="text"><span>阅读并同意协议获取二维码</span></div>
                                <img class="image-center" :src="qrcode_url">
                            </div>
                            <p class="text">开启数媒新世界</p>
                            <div class="urs-wechat-info">
                                <span id="pwd_login_entry" @click="goTo('wechat')">
                                    手机号登录
                                </span>
                                <Divider class="divider" type="vertical" />
                                <span id="register" @click="changeCurrent('registerLogin', '手机号注册')">手机号注册</span>
                            </div>
                        </div>
                        <p class="service">
                            <Checkbox v-model="agreed">
                                我已阅读并同意
                            </Checkbox>
                            <a @click="handleArticle('user_agreement')">用户协议</a>和<a @click="handleArticle('user_privacy_protocol')">隐私政策</a>。
                        </p>
                    </div>
                    <!-- 更改密码 -->
                    <div v-show="current==='changePwd'" class="urs-change-pwd urs-login-common">
                        <h2 class="login-title">{{ title }}</h2>
                        <Form ref="formChangePwd" :model="formChangePwd" :rules="ruleInline" inline>
                            <FormItem class="formItem" prop="user">
                                <Input type="text" v-model="formChangePwd.user" size="large" placeholder="请输入手机号" @keyup.native.enter="handleSubmit('formChangePwd')">
                                    <template #prefix>
                                        <areas-component :areas="areas" v-model="areaCode"></areas-component>
                                    </template>
                                </Input>

                            </FormItem>
                            <FormItem prop="message" style="width: 223px;">
                                <Input v-model="formChangePwd.message" size="large" placeholder="请输入验证码" @keyup.native.enter="handleSubmit('formChangePwd')" />

                            </FormItem>
                            <span class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('formChangePwd', 'psw')">获取验证码</span>
                            <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>

                            <FormItem class="formItem" prop="password">
                                <Input type="password" v-model="formChangePwd.password" size="large" placeholder="请输入新密码" autocomplete="new-password" @keyup.native.enter="handleSubmit('formChangePwd')" />

                            </FormItem>
                        </Form>

                        <Button class="confirm-button" type="primary" size="large" @click="handleSubmit('formChangePwd')" :loading="loading">更改密码</Button>
                        <p class="others-info">想起密码？<span class="to-login" @click="changeCurrent('wechatLogin', '账号密码登录', 'formChangePwd')">去登录</span></p>
                    </div>
                    <!-- 手机号注册 -->
                    <div v-show="current==='registerLogin'" class="urs-register-login urs-login-common">
                        <h2 class="login-title">{{ title }}</h2>
                        <Form ref="formRegister" :model="formRegister" :rules="ruleInline" inline>
                            <FormItem class="formItem" prop="user">
                                <Input id="register_account" type="text" size="large" v-model="formRegister.user" placeholder="请输入手机号" @keyup.native.enter="handleSubmit('formRegister')">
                                    <template #prefix>
                                        <areas-component :areas="areas" v-model="areaCode"></areas-component>
                                    </template>
                                </Input>

                            </FormItem>
                            <FormItem prop="message" style="width: 223px;">
                                <Input type="text" size="large" v-model="formRegister.message" placeholder="请输入验证码" @keyup.native.enter="handleSubmit('formRegister')" />

                            </FormItem>
                            <span class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('formRegister', 'register')">获取验证码</span>
                            <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
                            <FormItem class="formItem" prop="passwordRe">
                                <Input id="register_passwd" type="password" autocomplete="new-password" size="large" v-model="formRegister.passwordRe" placeholder="请输入6-30位密码" @keyup.native.enter="handleSubmit('formRegister')" />
                            </FormItem>
                        </Form>
                        <Button id="register_cmt" class="confirm-button" type="primary" size="large" @click="handleSubmit('formRegister')">注册账号</Button>
                        <p class="others-info">已有账号？<span class="to-login" @click="changeCurrent('wechatLogin', '账号密码登录', 'formRegister')">去登录</span></p>
                        <p class="service">
                            <Checkbox v-model="agreed">
                                我已阅读并同意
                            </Checkbox>
                            <a @click="handleArticle('user_agreement')">用户协议</a>和<a @click="handleArticle('user_privacy_protocol')">隐私政策</a>。
                        </p>
                    </div>
                    <!-- 企业注册 -->
                    <div v-show="current === 'registerBusiness' || current === 'createBusiness'" class="biz-register-block">
                        <div v-show="current==='registerBusiness'" class="urs-register urs-login-common">
                            <h2 class="login-title">{{ title }}</h2>
                            <Form ref="formRegisterBiz" :model="formRegisterBiz" :rules="ruleInline" inline>
                                <FormItem prop="user" class="formItem">
                                    <Input id="sms_login_account" type="text" size="large" v-model="formRegisterBiz.user" placeholder="请输入手机号" @keyup.native.enter="handleRegisterBizContinue">
                                        <template #prefix>
                                            <areas-component :areas="areas" v-model="areaCode"></areas-component>
                                        </template>
                                    </Input>
                                </FormItem>
                                <br>
                                <FormItem prop="message" style="width: 223px;">
                                    <Input id="sms_login_msg" type="text" size="large" v-model="formRegisterBiz.message" placeholder="请输入验证码" @keyup.native.enter="handleRegisterBizContinue" />
                                </FormItem>
                                <span id="sms_login_verify" class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('formRegisterBiz', 'auto_register')">获取验证码</span>
                                <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
                            </Form>
                            <Button class="confirm-button" size="large" type="primary" :loading="loading" @click="handleRegisterBizContinue">验证并继续</Button>
                            <p class="others-info">已有账号？<span class="to-login" @click="changeCurrent('wechatLogin', '账号密码登录', 'formRegisterBiz')">去登录</span></p>
                        </div>
                        <!-- 创建企业 -->
                        <div v-show="current==='createBusiness'" class="create-biz urs-login-common">
                            <h2 class="business-title register">企业注册</h2>
                            <h2 class="business-title create">创建新企业</h2>
                            <p class="join-text line-overflow">{{ joinText }}</p>
                            <Form ref="formCreateBiz" :model="formCreateBiz" :rules="ruleInline">
                                <FormItem prop="name">
                                    <Input type="text" size="large" v-model="formCreateBiz.name" placeholder="请输入企业(团队)名称" />
                                </FormItem>
                                <FormItem prop="bizID">
                                    <Select size="large" v-model="formCreateBiz.bizID" placeholder="请选择行业类型">
                                        <Option v-for="(item, index) in bizList" :key="index" :value="item.rowid">{{ item.text._ }}</Option>
                                    </Select>
                                </FormItem>
                                <FormItem prop="inviteCode" class="inviteCode">
                                    <Input type="text" size="large" v-model="formCreateBiz.inviteCode" placeholder="请输入邀请码" @keyup.native.enter="handleSubmit('formCreateBiz')" />
                                    <Tooltip content="邀请码需要跟平台申请，请联系客服" placement="right">
                                        <Icon type="md-help-circle" class="help pointer" size="24" />
                                    </Tooltip>
                                </FormItem>
                            </Form>
                            <Button class="confirm-button" size="large" type="primary" :loading="loading" @click="handleSubmit('formCreateBiz')">确认注册</Button>
                            <p class="others-info">已有账号？<span class="to-login" @click="changeCurrent('wechatLogin', '账号密码登录', 'formCreateBiz')">去登录</span></p>
                        </div>
                        <p class="service">
                            <Checkbox v-model="agreed">
                                我已阅读并同意
                            </Checkbox>
                            <a @click="handleArticle('user_agreement')">用户协议</a>和<a @click="handleArticle('user_privacy_protocol')">隐私政策</a>。
                        </p>
                    </div>
                </div>
                <div v-if="current !== 'createBusiness' && current !== 'registerBusiness'" class="business-register pointer" @click="changeCurrent('registerBusiness', '注册企业', null)">企业注册</div>
            </div>
            <div v-else class="card selection-block">
                <div class="account-block">
                    <h2 class="login-title">登录企业帐号</h2>
                    <ul class="items-wrap">
                        <li class="item pointer" v-for="item in organizationlist" :key="item.rowid" @click="goToCompany(item.organization_id, item.organization_name)">
                            <img :src="`/cym/${item.avatar_uri}`">
                            <p class="name line-overflow">{{ item.organization_name }}</p>
                        </li>
                    </ul>
                </div>

                <div class="checkbox"><Checkbox v-model="isRemember">记住选择，仅主动退出登录可重新选择</Checkbox></div>
                <p class="others-info">
                    不是该帐号？
                    <span class="to-login pointer" @click="logout">重新登录</span>
                </p>
                <p class="personal-login"><span class="pointer" @click="goToPerson">管理我的个人资源</span></p>
            </div>
        </div>
        <article-modal
            :show="articleModalShow"
            :content="articleContent"
            @cancel="hideArticleModal"
        ></article-modal>
    </div>  
</template>
<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import cookies from 'vue-cookies'
    import sha1 from 'sha1'
    import util from '@/libs/util'
    import { login } from '@/mixins/index'
    import recordFooter from '@/views/layout/components/recordFooter'
    import videoComponent from '@/components/video'
    import articleModal from './components/articleModal'
    import areasComponent from './components/areasComponent'

    export default {
        name: "",
        mixins: [login],
        components: {
            recordFooter,
            Swiper,
            SwiperSlide,
            videoComponent,
            articleModal,
            areasComponent
        },
        directives: {
            swiper: directive
        },
        props: [],
        data () {
            return {
                sources: [
                    {
                        src: require('../../assets/login_swiper.png'),
                        type: 'image',
                        rowid: 1
                    }
                ],
                options: {
                    controls: true,
                    autoplay: false,
                    fluid: true,
                    muted: true

                },
                title: '',
                formPwdLogin: {
                    user: '',
                    password: '',
                    code: ''
                },
                ruleInline: {
                    user: [
                        { required: true, type: 'string', validator: (rule, value, callback) => {
                            var regex = /^1[3456789]\d{9}$/
                            if (this.areaCode !== '86') {
                                regex = /^\d{1,20}$/
                            }
                            if (!regex.test(value)) {
                                callback(new Error('请输入手机号码'))
                            } else {
                                callback()
                            }
                        }, trigger: 'blur' }
                    ],
                    password: [
                        { required: true, type: 'string', min: 6, max: 30,  message: '请输入6-30位密码', trigger: 'blur' }
                    ],
                    message: [
                        { required: true, type: 'string', message: '请输入验证码', trigger: 'blur' },
                        { type: 'string', pattern: /^\d{4}$/, message: '请输入4位数字', trigger: 'blur'}
                    ],
                    passwordRe: [
                        { required: false, type: 'string', min: 6, max: 30,  message: '请输入6-30位密码', trigger: 'blur' }
                    ],
                    code: [
                        { required: true, type: 'string', message: '请输入验证码', trigger: 'blur' }

                    ],
                    name: [
                        { required: true, type: 'string', message: '请输入', trigger: 'blur' },
                        { type: 'string', max: 32, message: '最多32个字符', trigger: 'blur' }
                    ],
                    bizID: [
                        { required: true, type: 'number', message: '请选择', trigger: 'blur' }
                    ],
                    inviteCode: [
                        { required: true, type: 'string', message: '请输入', trigger: 'blur' },
                        { type: 'string', pattern: /^[0-9a-zA-Z]{6}$/, message: '请输入6位数字、英文字母', trigger: 'blur' }
                    ]
                },
                formMesLogin: {
                    user: '',
                    message: ''
                },
                formChangePwd: {
                    user: '',
                    password: '',
                    message: ''
                },
                formRegister: {
                    user: '',
                    message: '',
                    passwordRe: ''
                },
                formRegisterBiz : {
                    user: '',
                    message: ''
                },
                formCreateBiz: {
                    name: '',
                    bizID: '',
                    inviteCode: ''
                },
                sendMsgDisabled: false,
                countDownTime: 60,
                timer: null,
                timer2: null,
                current: 'wechatLogin',
                uuid: util.randomWord(20),
                loading: false,
                bizList: [],
                organizationlist: [],
                isRemember: false,
                selectable: false,
                swiperOptions: {
                    allowTouchMove: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 1
                    },
                    init: false,
                    watchOverflow: true,
                    slidesPerView: 1,
                    freeMode: true,
                    freeModeSticky : true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    on: {
                        slideChangeTransitionEnd: () => {
                            if (this.sources[this.swiper.activeIndex].type === 'video') {
                                this.$refs['media'][this.swiper.activeIndex].player.src([{
                                    src: this.sources[this.swiper.activeIndex].src,
                                    type: 'video/mp4'
                                }])
                                this.$refs['media'][this.swiper.activeIndex].player.play()
                                // this.$refs['media'][0].player.muted(false)
                            }
                        },
                        slideChangeTransitionStart: () => {
                            if (this.sources[this.swiper.previousIndex].type === 'video') {
                                this.$refs['media'][this.swiper.previousIndex].player.pause()
                                this.$refs['media'][this.swiper.previousIndex].player.currentTime(0)
                            }
                        }
                    }
                },
                joinText: '',
                qrcode_url: '',
                articleModalShow: false,
                articleContent: '',
                agreed: true,
                areaCode: ''

            }
        },
        computed: {
            imgCode() {
                return `${this.$store.state.cloud_api}/cym/auth/captchas/${this.uuid}`
            },
            swiper() {
                return this.$refs.mySwiper.$swiper
            },
            avatar_uri() {
                return this.$store.state.avatar_uri
            },
            username() {
                return this.$store.state.username
            },
            areas() {
                return this.$store.state.areas
            }
        },
        watch: {
            current(val) {
                if (val !== 'wechatLogin') {
                    clearTimeout(this.timer2)
                }
            }
        },
        methods: {
            handleSubmit(name) {
                return this.$refs[name].validate((valid) => {
                    if (valid) {
                        let params = {},
                            url = '',
                            text = '阅读并同意协议后方可登录';
                        if (name === 'formRegister') {
                            url = 'auth/register_by_sms'
                            params.mobile = this.formRegister.user
                            params.area_code = this.areaCode
                            params.password = sha1(this.formRegister.passwordRe)
                            params.sms_code = this.formRegister.message
                            params.auto_register = true
                            if (this.ticket) {
                                params.wxmp_ticket = this.ticket
                            }

                        } else if (name === 'formPwdLogin') {
                            url = 'auth/access_token'
                            params.mobile = this.formPwdLogin.user
                            params.area_code = this.areaCode
                            params.password = sha1(this.formPwdLogin.password)
                            params.image_code = this.formPwdLogin.code
                            params.captcha_key = this.uuid

                        } else if (name === 'formChangePwd') {
                            url = 'auth/reset_password_by_sms'
                            params.mobile = this.formChangePwd.user
                            params.area_code = this.areaCode
                            params.password = sha1(this.formChangePwd.password)
                            params.sms_code = this.formChangePwd.message

                        } else if (name === 'formMesLogin') {
                            url = 'auth/access_token_by_sms'
                            params.mobile = this.formMesLogin.user
                            params.area_code = this.areaCode
                            params.sms_code = this.formMesLogin.message
                            params.auto_register = false

                        } else if (name === 'formCreateBiz') {
                            url = 'organizations'
                            params.name = this.formCreateBiz.name
                            params.business_id = this.formCreateBiz.bizID
                            params.invite_code = this.formCreateBiz.inviteCode
                            text = '阅读并同意协议后方可注册'
                        }
                        if (!this.agreed) {
                            return this.$Message.error(text)
                        }
                        this.loading = true
                        this.$api.post(url, params).then(({ data, message, rowid }) => {
                            if (this.ticket) this.ticket = ''
                            if (name === 'formChangePwd') {
                                this.$Message.success(message)
                                this.current = 'pwdLogin'
                            }  else if (name === 'formCreateBiz') {
                                localStorage.setItem('organization_name', this.formCreateBiz.name)
                                this.$api.put('auth/access_token', { organization_id: rowid }).then(({ data }) => {
                                    cookies.set('Authorization', data.token, -1)
                                    window.localStorage.setItem('token',data.token);
                                    window.localStorage.setItem('username', data.account);
                                    this.$router.replace({ name: 'companySpaces' })
                                }).catch(() => {}).finally(() => {
                                    this.loading = false
                                })
                            } else {
                                cookies.set('Authorization', data.token, -1)
                                window.localStorage.setItem('token',data.token);

                                //判断是否是受邀请
                                let invite_user_code = this.$route.query.invite_user_code
                                if (invite_user_code) {
                                    //接受邀请，自动登录企业
                                    this.$api.post('organizations/current/users', { invite_user_code: invite_user_code }).then(({ receive_org_id, receive_org_name }) => {
                                        this.isRemember = true
                                        this.goToCompany(receive_org_id, receive_org_name)
                                        this.$Message.success('您已加入企业')
                                    }).catch(() => {})
                                    return
                                }
                                this.hanleLoginSelect()
                            }
                            this.$refs[name].resetFields();

                        }).catch(() => {}).finally(() => {
                            this.loading = false
                            this.clearTimer()
                        })
                    }
                })
            },
            hanleLoginSelect() {
                this.getOrganizationList().then((data) => {
                    this.organizationlist = data
                    let id = Number(localStorage.getItem('organization_id')),
                        account = localStorage.getItem('account'),
                        name = '',
                        len = this.organizationlist.length,
                        skip = false;

                    //无企业时跳转个人
                    if (len === 0) {
                        this.goToPerson()
                        return
                    }

                    //记住选择时自动跳转
                    if (account === 'company') {
                        if (id) {
                            for (let i = 0; i < len; i++) {
                                if (id === this.organizationlist[i].organization_id) {
                                    name = this.organizationlist[i].organization_name
                                    skip = true
                                    break
                                }
                            }
                            if (skip) {
                                this.isRemember = true
                                this.goToCompany(id, name)
                            } else {
                                localStorage.removeItem('account')
                                localStorage.removeItem('organization_id')
                                this.selectable = true
                            }
                        }
                    } else if (account === 'person') {
                        this.isRemember = true
                        this.goToPerson()
                    } else {
                        this.selectable = true
                    }
                }).catch(() => {})
                this.$store.dispatch('GET_BUCKET_TOKENS')
            },
            countDown() {
                if ((this.countDownTime--) <= 0) {
                    this.countDownTime = 60
                    this.sendMsgDisabled = false
                    clearInterval(this.timer)
                }
            },
            getMesg(name, sms_type) {
                this.$refs[name].validateField('user', (error) => {
                    if (!error) {
                        let params = {
                            sms_type: sms_type,
                            area_code: this.areaCode
                        }
                        this.$api.get(`auth/captchas/sms/${this[name].user}`, { params }).then(({ code }) => {
                            if (code === 200) {
                                this.sendMsgDisabled = true
                                clearInterval(this.timer)
                                this.timer = setInterval(this.countDown, 1000)
                            }
                        }).catch(() => {})
                    }
                })
            },
            changeCurrent(current, title, name, clearTicket=true) {
                // if (!this.agreed) {
                //     if (current === 'registerLogin') {
                //         return this.$Message.error('阅读并同意协议后方可注册')
                //     } else if (current === 'changePwd') {
                //         return this.$Message.error('阅读并同意协议后方可更改')
                //     }
                // }
                if (current === 'registerLogin' || current === 'registerBusiness') {
                    clearTimeout(this.timer2)
                    this.agreed = false
                } else {
                    this.agreed = true
                }
                if (clearTicket) {
                    this.ticket = ''
                }
                if (name) {
                    this.$refs[name].resetFields();
                } else {
                    //
                    this.$refs['formMesLogin'].resetFields()
                    this.$refs['formChangePwd'].resetFields()
                    this.$refs['formPwdLogin'].resetFields()
                    this.$refs['formRegister'].resetFields()
                    this.$refs['formRegisterBiz'].resetFields()
                }
                if (current === 'wechatLogin') {
                    //获取登录二维码
                    this.goTo()
                }
                this.current = current
                this.uuid = util.randomWord(20)
                this.title = title
                this.clearTimer()
            },
            changeImgCode() {
                this.uuid = util.randomWord(20)
            },
            handleTarget(e) {
                let eles = e.currentTarget.children,
                    len = eles.length;

                for (var i = 0; i < len; i++) {
                    eles[i].setAttribute('class', '')
                }

                e.target.setAttribute('class', 'target')
                this.current = e.target.dataset.name
                if (this.current === 'pwdLogin') {
                    this.$refs['formMesLogin'].resetFields()
                } else if (this.current === 'mesgLogin') {
                    this.$refs['formPwdLogin'].resetFields()
                    // this.formPwdLogin = {
                    //     code: '',
                    //     password: '',
                    //     user: ''
                    // }
                }
            },
            goTo(from) {
                this.agreed = true
                clearTimeout(this.timer2)
                if (from === 'wechat') {
                    this.current = 'pwdLogin'
                    this.ticket = ''
                } else {
                    this.current = 'wechatLogin'
                    this.$refs['formPwdLogin'].resetFields();
                    this.$refs['formMesLogin'].resetFields();
                    this.$nextTick(() => {
                        this.timer2 = setTimeout(this.getQRcode, 0)
                    })
                }
            },
            getQRcode() {
                let params = {
                    ticket: this.ticket
                }
                this.$api.get('auth/captchas/qrcode/wx_mp', { params }).then(({ qrcode_url, status, ticket, access_token }) => {
                    //status: created新创建，waiting等待扫码，register可注册，login已登录
                    this.qrcode_url = qrcode_url
                    this.status = status
                    this.ticket = ticket
                    if (status === 'register') {
                        this.changeCurrent('registerLogin', '绑定手机号', null, false)
                        clearTimeout(this.timer2)
                    } else if (status === 'login') {
                        cookies.set('Authorization', access_token, -1)
                        window.localStorage.setItem('token', access_token);
                        clearTimeout(this.timer2)
                        this.$api.post('auth/access_token_by_wxmp_ticket', { wxmp_ticket: this.ticket }).then(({ data }) => {
                            cookies.set('Authorization', data.token, -1)
                            window.localStorage.setItem('token',data.token);
                            this.hanleLoginSelect()
                        }).catch(() => {})
                    } else {
                        this.timer2 = setTimeout(this.getQRcode, 1000)
                    }
                }).catch(() => {
                    clearTimeout(this.timer2)
                })
            },
            handleUrsLogin(index) {
                let eles = this.$refs['urs-login-tab'].children,
                    len = eles.length;

                for (var i = 0; i < len; i++) {
                    eles[i].setAttribute('class', '')
                }
                eles[index].setAttribute('class', 'target')
                this.current = eles[index].dataset.name
                this.$refs['formPwdLogin'].resetFields();
                this.$refs['formMesLogin'].resetFields();
            },
            handleRegisterBizContinue() {
                this.$refs['formRegisterBiz'].validate((valid) => {
                    if (valid) {
                        let params = {
                            mobile: this.formRegisterBiz.user,
                            sms_code: this.formRegisterBiz.message,
                            auto_register: true
                        }
                        if (!this.agreed) {
                            return this.$Message.error('阅读并同意协议后方可注册')
                        }
                        this.loading = true
                        this.$api.post('auth/access_token_by_sms', params).then(({ data, new_register }) => {
                            if (new_register) {
                                this.joinText = '已为您自动创建个人账号，你可继续创建企业'
                            }
                            window.localStorage.setItem('token',data.token);
                            this.current = 'createBusiness'
                            this.getOrganizationList().then((data) => {
                                let len = data.length,
                                    text = '';
                                for (let i = 0; i < len; i++) {
                                    text += data[i].organization_name
                                    if (i !== len - 1) {
                                        text += '、'
                                    }
                                }
                                if (text) {
                                    this.joinText= `你已加入“${text}“`
                                }

                            }).catch(() => {})
                            this.$api.get('specifications/business/list').then(({ data }) => {
                                this.bizList = data
                            }).catch(() => {})
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            logout() {
                this.ticket = ''
                this.joinText = ''
                this.selectable = false
                this.current = 'pwdLogin'
                this.clearTimer()
                localStorage.removeItem('token');
            },
            clearTimer() {
                this.sendMsgDisabled = false
                this.countDownTime = 60
                this.sendMsgDisabled = false
                clearInterval(this.timer)
            },
            hideArticleModal() {
                this.articleModalShow = false
                this.articleContent = ''
            },
            handleArticle(key) {
                let params = {
                    key: key
                }
                this.$api.get('articles/get_by_key', { params }).then(({ data }) => {
                    this.articleContent = this.md.render(data.content)
                }).catch(() => {})
                this.articleModalShow = true
            },
            initMarkdown() {
                let MarkdownIt = require('markdown-it'),
                    sub = require('markdown-it-sub'),
                    sup = require('markdown-it-sup'),
                    ins = require('markdown-it-ins'),
                    mark = require('markdown-it-mark'),
                    emoji = require('markdown-it-emoji'),
                    container = require('markdown-it-container'),
                    deflist = require('markdown-it-deflist'),
                    abbr = require('markdown-it-abbr'),
                    footnote = require('markdown-it-footnote'),
                    taskLists = require('markdown-it-task-lists'),
                    toc = require('markdown-it-toc'),
                    hljs = require('highlight.js'),
                    katex = require('markdown-it-katex-external'),
                    md = new MarkdownIt({
                        highlight: function (str, lang) {
                            if (lang && hljs.getLanguage(lang)) {
                              try {
                                return '<pre><div class="hljs"><code class="' + lang + '">' + hljs.highlight(lang, str, true).value + '</code></div></pre>';
                              } catch (err) {
                                console.log(err)
                              }
                            }

                            return '<pre><code class="' + lang + '">' + md.utils.escapeHtml(str) + '</code></pre>';
                        },
                        html: true,        // Enable HTML tags in source
                        xhtmlOut: true,        // Use '/' to close single tags (<br />).
                        breaks: true,        // Convert '\n' in paragraphs into <br>
                        langPrefix: 'language-',  // CSS language prefix for fenced blocks. Can be
                        linkify: false,        // 自动识别url
                        typographer: true,
                        quotes: '“”‘’'

                    });
                // add target="_blank" to all link
                let defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
                    return self.renderToken(tokens, idx, options);
                };
                md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
                    let hIndex = tokens[idx].attrIndex('href');
                    if (tokens[idx].attrs[hIndex][1].startsWith('#')) return defaultRender(tokens, idx, options, env, self);
                    // If you are sure other plugins can't add `target` - drop check below
                    let aIndex = tokens[idx].attrIndex('target');

                    if (aIndex < 0) {
                        tokens[idx].attrPush(['target', '_blank']); // add new attribute
                    } else {
                        tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
                    }

                    // pass token to default renderer.
                    return defaultRender(tokens, idx, options, env, self);
                };
                md.use(sub)
                    .use(sup)
                    .use(ins)
                    .use(mark)
                    .use(emoji)
                    .use(container, 'hljs-left') /* align left */
                    .use(container, 'hljs-center')/* align center */
                    .use(container, 'hljs-right')/* align right */
                    .use(deflist)
                    .use(abbr)
                    .use(footnote)
                    .use(taskLists)
                    .use(toc)
                    .use(katex)

                return md
            },
            async getAreasList() {
                await this.$store.dispatch('GET_AREAS_LIST')
                if (this.$store.state.areas.length) {
                    this.areaCode = this.$store.state.areas[0].area_code
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
            clearTimeout(this.timer2)
            this.swiper.destroy();
        },
        mounted() {
            this.$nextTick(() => {
                this.swiper.init()
                this.swiper.slideTo(0, 0, false)
                //自动播放媒体
                if (this.sources[0].type === 'video') {
                    this.$refs['media'][0].player.src([{
                        src: this.sources[0].src,
                        type: 'video/mp4'
                    }])
                    this.$refs['media'][0].player.play()
                }
                this.getAreasList()
            })
            this.md = this.initMarkdown()
            //获取登录二维码
            this.goTo()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";

@deep: ~'>>>';
.formItem @{deep} .ivu-input-prefix {
    width: 60px;
}
.formItem @{deep} .ivu-input-with-prefix {
    padding-left: 60px;
}
.area-code {
    display: block;
    width: 60px;
}
.img-code-wrap @{deep} .ivu-input-group-append {
    border: 0;
    padding: 0;
}
.img-code {
    display: block;
    width: 100px;
    height: 40px;
}
#login {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    min-width: 1200px;
    min-height: 100%;

    .left-section, .right-section {
        position: relative;
        width: 50%;
    }
    .right-section {
        padding-top: 0px;
        min-height: 100%;
        background: #f8f8f8;
    }
    .left-section {
        padding: 0px 146px 0;
        background: #fff;
        min-height: 100%;
        .content {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        .logo {
            position: relative;
            margin-bottom: 70px;
            width: 197px;
        }
        .login-swiper-wrap {
            margin: 0 auto 50px;
            width: 100%;
            .login-swiper {
                width: 100%;
                max-width: 668px;
                .img-wrap {
                    margin-bottom: 30px;
                    width: 100%;
                    max-height: 569px;
                    img {
                        display: block;
                        width: 100%;
                    }
                    .video-js {
                        position: relative;
                        top: 0;
                        transform: translate(-50%, 0)
                    }
                }
            }
        }
        .record-wrap {
            text-align: center;
        }
    }
    @media screen and (max-width: 1500px) {
        .left-section {
            padding: 0 80px;
        }
    }
    .card {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        width: 440px;
        height: 575px;
        border-radius: 10px;
        background: #fff;
        .urs-area {
            position: relative;
            .urs-login-tab {
                display: inline-block;
                margin: 30px 0 123px 34px;
                li {
                    cursor: pointer;
                    position: relative;
                    display: inline-block;
                    padding-right: 39px;
                    font-size: 18px;
                    line-height: 29px;
                    font-weight: 700;
                    color: #092c4c;
                }
                .target {
                    &::after {
                        position: absolute;
                        left: 0;
                        bottom: -5px;
                        content: '\200B';
                        width: 72px;
                        height: 5px;
                        background: @primary-color;
                        border-radius: 2.5px;
                    }
                }
            }
            .urs-login-common {
                padding: 0 46px;
            }
            .urs-wechat-login {
                .img-wrap {
                    position: relative;
                    display: block;
                    width: 167px;
                    height: 167px;
                    margin: 0 auto;
                    .text {
                        display: flex;
                        position: absolute;
                        top: 0;
                        left: 0;
                        align-items: center;
                        padding: 0 20px;
                        width: 100%;
                        height: 167px;
                        background: rgba(0, 0, 0, 0.8);
                        font-size: 14px;
                        color: #fff;
                        text-align: center;
                        z-index: 1;
                    }
                    .image-center {
                        z-index: 0;
                    }
                }
                > .text {
                    margin: 30px 0;
                    font-size: 22px;
                    line-height: 29px;
                    text-align: center;
                }
                .urs-wechat-info {
                    margin-bottom: 18px;
                    text-align: center;
                    color: @primary-color;
                    .divider {
                        background: @primary-color;
                    }
                    & > span {
                        cursor: pointer;
                    }
                }
            }
            .usrs-wechat {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
                width: 122px;
                height: 122px;
                background: url('~@/assets/wechat.png') 0 0 no-repeat;
                background-size: 100% 100%;
            }
            .service {
                padding: 0 34px;
                font-size: 14px;
                color: #afb4ba;
                text-align: center;
                a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover {
                        color: @primary-color;
                    }
                }
            }
            .usrs-pc {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
                width: 122px;
                height: 122px;
                background: url('~@/assets/pc.png') 0 0 no-repeat;
                background-size: 100% 100%;
            }
            .login-title {
                margin: 0 0 26px 0;
                padding-top: 106px;
                height: 130px;
                font-size: 24px;
                font-weight: 700;
                color: #092c4c;
                line-height: 29px;
                text-align: center;
            }
            .business-title {
                font-size: 24px;
                font-weight: 700;
                color: #092c4c;
                line-height: 29px;
                text-align: center;
            }

            .formItem {
                width: 100%;
            } 
            .tips {
                margin: 3px 0 25px;
                height: 15px;
                font-weight: 400;
                line-height: 15px;
                .message-login {
                    cursor: pointer;
                    color: @primary-color;
                }
                .forget-pwd {
                    cursor: pointer;
                }
            }
            .message-get {
                cursor: pointer;
                display: inline-block;
                width: 115px;
                height: 40px;
                background: #ececec;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 700;
                line-height: 40px;
                text-align: center;
            }
            .others{
                margin-top: 19px;
                text-align: center;
                font-size: 16px;
                img {
                    margin-right: 8px;
                    width: 26px;
                    height: 26px;
                    vertical-align: middle;
                }
            }
            .others-info {
                margin-top: 20px;
                text-align: center;
                .to-login {
                    cursor: pointer;
                    color: @primary-color;
                }
            }
            .confirm-button {
                margin-bottom: 86px;
                width: 100%;
            }
            .urs-register-login {
                .confirm-button {
                    margin-bottom: 0;
                }
                .others-info {
                    margin: 20px 0 33px;
                }
            }
        }
        .business-register {
            position: absolute;
            bottom: 53px;
            right: -36px;
            padding: 8px 0;
            width: 36px;
            background: @primary-color;
            color: #fff;
            font-size: 19px;
            line-height: 36px;
            writing-mode: vertical-lr;
        }
        .biz-register-block {
            .confirm-button {
                margin: 66px 0 4px;
            }
            .others-info {
                margin-bottom: 33px;
            }
            .create-biz {
                .login-title {
                    margin: 0;
                }
                .register {
                    margin: 0px 0 15px;
                    padding-top: 49px;
                }
                .create {
                    margin-bottom: 8px;
                    text-align: left;
                }
                .join-text {
                    margin-bottom: 18px;
                    font-size: 14px;
                    line-height: 22px;
                    color: #afb4ba;
                }
                .confirm-button {
                    margin-top: 0;
                }
                .inviteCode {
                    position: relative;
                    .help {
                        position: absolute;
                        top: 50%;
                        right: -32px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .account-block {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 29px 32px 0;
            height: calc(100% - 182px);
            .login-title {
                padding-left: 45px;
                font-size: 24px;
                font-weight: 700;
                color: #092c4c;
                line-height: 58px;
            }
            .items-wrap {
                position: relative;
                width: 376px;
                .item {
                    position: relative;
                    margin-bottom: 15px;
                    padding: 16px 0 13px 43px;
                    width: 100%;
                    height: 95px;
                    background: #f5f5f5;
                    border-radius: 5px;
                    font-size: 30px;
                    line-height: 50px;
                    img {
                        display: inline-block;
                        width: 66px;
                        height: 66px;
                        border-radius: 50%;
                    }
                    .name {
                        position: absolute;
                        left: 129px;
                        top: 50%;
                        width: 200px;
                        transform: translateY(-50%);
                        font-size: 20px;
                        color: #000000;
                        font-weight: 400;
                    }
                }
            }
        }
        .personal-login {
            margin-top: 40px;
            text-align: center;
            span {
                color: @primary-color;
            }
        }
        .checkbox {
            margin-top: 20px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 29px;
        }
        .others-info {
            text-align: center;
            margin-top: 20px;
            .to-login {
                color: @primary-color;
            }
        }
    }
    .record-num-login {
        position: absolute;
        left: 36px;
        bottom: 24px;
        background: transparent;
        a {
            font-size: 16px;
            font-weight: 400;
            line-height: 48px;
            color: #fff;
        }
    }
}
</style>